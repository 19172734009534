import React, { useState, useEffect } from "react";
import logo from "../components/images/logo.svg";
import Header from "./header";
import { ToastContainer } from "react-toastify";
import Footer from "./footer";

const PrivacyPolicy = () => {
    
  return (
    <>
      <Header />
      <div className="policy" >
        <div className="heading" >
          <h1 style={{ paddingTop: "90px" }}>Privacy Policy</h1>
          </div>
          <p className="policy-date" >
            <strong>Last Updated On:</strong> 23-Apr-2024<br></br>
            <strong>Effective Date:</strong> 07-Feb-2024
          </p>
          <p style={{ paddingTop: "10px" }}>
            This Privacy Policy describes the policies of ScaleUpHQ, Kakkanad,
            Cochin, Kerala, 682030, India, email: support@scaleuphq.io, on the
            collection, use and disclosure of your information that we collect
            when you use our website ( https://scaleuphq.io ). (the “Service”).
            By accessing or using the Service, you are consenting to the
            collection, use and disclosure of your information in accordance
            with this Privacy Policy. If you do not consent to the same, please
            do not access or use the Service.
          </p>
          <p>
            We may modify this Privacy Policy at any time without any prior
            notice to you and will post the revised Privacy Policy on the
            Service. The revised Policy will be effective 180 days from when the
            revised Policy is posted in the Service and your continued access or
            use of the Service after such time will constitute your acceptance
            of the revised Privacy Policy. We therefore recommend that you
            periodically review this page.
          </p>
       
        <br></br>

<div >
        <u><h3>Information we collect:</h3></u>
          <p>
            We will collect and process the following personal information about
            you:
          </p>
          <ul>
            <li>Name</li>
            <li>Email</li>
            <li>Mobile</li>
            <li>Work Address</li>
            <li>Company Name</li>
            <li>Company Domain</li>
            <li>Company Registration Number</li>
            <li>Country</li>
            <li>Designation</li>
          </ul>
        </div>
        <br></br>
        <div >
        <u><h3>How we use your information:</h3></u>
          <p>
            We will use the information that we collect about you for the
            following purposes:
          </p>
          <ul>
            <li>Marketing/ Promotional</li>
            <li>Creating user account</li>
            <li>Testimonials</li>
            <li>Customer feedback collection</li>
            <li>Enforce T&C</li>
            <li>Support</li>
            <li>Administration info</li>
            <li>Site protection</li>
            <li>User to user comments</li>
            <li>Dispute resolution</li>
            <li>Manage user account</li>
          </ul>
        </div><br></br>
      
        

       
        <div>
          <u><h3>Retention of your information:</h3></u>
          <p>
            We will retain your personal information with us for 90 days to 2
            years after users terminate their accounts or for as long as we need
            it to fulfill the purposes for which it was collected as detailed in
            this Privacy Policy. We may need to retain certain information for
            longer periods such as record-keeping / reporting in accordance with
            applicable law or for other legitimate reasons like enforcement of
            legal rights, fraud prevention, etc. Residual anonymous information
            and aggregate information, neither of which identifies you (directly
            or indirectly), may be stored indefinitely.
          </p>
        </div>

        <div >
         <u><h3>Your Rights:</h3></u>
          <p>
            Depending on the law that applies, you may have a right to access
            and rectify or erase your personal data or receive a copy of your
            personal data, restrict or object to the active processing of your
            data, ask us to share (port) your personal information to another
            entity, withdraw any consent you provided to us to process your
            data, a right to lodge a complaint with a statutory authority and
            such other rights as may be relevant under applicable laws. To
            exercise these rights, you can write to us at support@scaleuphq.io.
            We will respond to your request in accordance with applicable law.
          </p>
          <p>
            Do note that if you do not allow us to collect or process the
            required personal information or withdraw the consent to process the
            same for the required purposes, you may not be able to access or use
            the services for which your information was sought.
          </p>
        </div>

        <div>
          <u><h3>Cookies etc.:</h3></u>
          <p>
            To learn more about how we use these and your choices in relation to
            these tracking technologies, please refer to our
            <a href="https://skillshelf.cubettech.in/cookiepolicy"> Cookie Policy.</a>
          </p>
        </div>

        <div>
          <u><h3>Security:</h3></u>
          <p>
            The security of your information is important to us and we will use
            reasonable security measures to prevent the loss, misuse or
            unauthorized alteration of your information under our control.
            However, given the inherent risks, we cannot guarantee absolute
            security and consequently, we cannot ensure or warrant the security
            of any information you transmit to us and you do so at your own
            risk.
          </p>
        </div>

        <div>
          <u><h3>Grievance/ data protection officer:</h3></u>
          <p>
            If you have any queries or concerns about the processing of your
            information that is available with us, you may email our Grievance
            Officer at ScaleUpHQ, Kakkanad, email: support@scaleuphq.io. We will
            address your concerns in accordance with applicable law.
          </p>
        </div>
        
        
      </div>
      <Footer/>
      <ToastContainer />
    </>
  );
};

export default PrivacyPolicy;
